import React from "react"

import SEO from "../components/site/seo"

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <p>Pas de chance, cette page n'existe pas...</p>
  </div>
)

export default NotFoundPage
